import React, { useEffect, useState } from "react";
import axios from "axios";
import "./JaklingkoTable.css";

function JaklingkoTable() {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [pto, setPto] = useState("TJ");
  const [totalPages, setTotalPages] = useState(1);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        //`http://192.168.60.100:4100/pqs/jli/feed/viewJliDatav2`,
        `https://portaldata.jaktraffic.my.id/pqs/jli/feed/viewJliDatav2`,
        {
          params: {
            page: currentPage,
            pageSize,
            pto,
          },
        }
      );
      setData(response.data.data || []);
      setTotalPages(response.data.totalPages || 1);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, pageSize, pto]);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  // Helper function to format timestamp
  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "N/A";
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-based
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day}-${month}-${year} pukul ${hours}:${minutes}`;
  };

  return (
    <div>
      {/* Filters */}
      <div className="filters-container">
        {/* PTO Filter */}
        <div className="button-group">
          {["TJ", "MRT", "LRT"].map((option) => (
            <button
              key={option}
              type="button"
              className={`button ${option} ${pto === option ? "active" : "inactive"}`}
              onClick={() => {
                setPto(option);
                setCurrentPage(1); // Reset to first page when filter changes
              }}
            >
              {option}
            </button>
          ))}
        </div>

        {/* Page Size Filter */}
        <div className="button-group">
          {[25, 50, 100, 200].map((size) => (
            <button
              key={size}
              type="button"
              className={`button ${pageSize === size ? "active" : "inactive"}`}
              onClick={() => {
                setPageSize(size);
                setCurrentPage(1); // Reset to first page when page size changes
              }}
            >
              {size}
            </button>
          ))}
        </div>
      </div>

      {/* Table */}
      <table className="table">
        <thead className={`table-header ${pto}`}>
          <tr>
          <th>No</th>
            <th>PTO Code</th>
            <th>Station Destination</th>
            <th>Payment Media</th>
            <th>Timestamp Destination</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
               <td>{(currentPage - 1) * pageSize + index + 1}</td>
              <td>{item.pto_code}</td>
              <td>{item.station_destination || "N/A"}</td>
              <td>{item.payment_media || "N/A"}</td>
              <td>{formatTimestamp(item.time_stamp_destination)}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="pagination-container">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default JaklingkoTable;
