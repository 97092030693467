import React, { useEffect, useState } from "react";
import axios from "axios";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Title,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import './chartStationTJ.css';
// Register Chart.js components globally
ChartJS.register(ArcElement, Tooltip, Legend, Title, CategoryScale, LinearScale, BarElement);

const ChartStationTJ = () => {
  const [stationData, setStationData] = useState<Record<string, number>>({});
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Station Destinations",
        data: [],
        backgroundColor: "rgba(75, 192, 192, 0.6)",
      },
    ],
  });

  const fetchData = async () => {
    try {
      const response = await axios.get(
        //"http://192.168.60.100:4100/pqs/jli/feed/viewJliDatav2",
        "https://portaldata.jaktraffic.my.id/pqs/jli/feed/viewJliDatav2",
        {
          params: { page: 1, pto: "TJ", pageSize: 200 },
        }
      );

      const data = response.data?.data || [];
      processChartData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const processChartData = (data: any[]) => {
    const stationCount: Record<string, number> = {};
    const fullLabels: Record<string, string> = {};

    // Count occurrences and keep track of full labels
    data.forEach((item) => {
      if (item.station_destination) {
        const fullLabel = item.station_destination; // Keep the full label
        const shortLabel = fullLabel.split(" - ")[0]; // Short label for chart

        stationCount[shortLabel] = (stationCount[shortLabel] || 0) + 1;
        fullLabels[shortLabel] = fullLabel; // Map short label to full label
      }
    });

    setStationData(stationCount);
    setFullLabels(fullLabels); // Store the full labels
  };

  const [fullLabels, setFullLabels] = useState<Record<string, string>>({});

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    // Update chart data whenever stationData changes
    const labels = Object.keys(stationData);
    const values = Object.values(stationData);

    setChartData({
      labels,
      datasets: [
        {
          label: "Station Destinations",
          data: values,
          backgroundColor: "rgba(75, 192, 192, 0.6)",
        },
      ],
    });
  }, [stationData]);

  return (
    <div className="chart-container">
            <div className="chart-title">Number of Station Destinations</div>
      <Bar
        data={chartData}
        options={{
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
            title: {
              display: true,
              text: "Number of Station Destinations",
            },
            tooltip: {
              callbacks: {
                // Display full label in tooltip
                label: function (context) {
                  const label = context.label;
                  // If there's a full label mapped, display it
                  if (fullLabels[label]) {
                    return fullLabels[label] + ": " + context.raw; // Show full label + value
                  }
                  return label + ": " + context.raw; // Fallback to short label
                },
              },
            },
          },
        }}
      />
    </div>
  );
};

export default ChartStationTJ;
