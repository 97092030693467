import React, { useState, useEffect } from "react";
import axios from "axios";
import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Title,
  CategoryScale,
  LinearScale,
} from "chart.js";
import "./chartPaymentTJ.css";

// Register Chart.js components globally
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  Title,
  CategoryScale,
  LinearScale
);

const ChartPaymentTJ = () => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Payment Media",
        data: [],
        backgroundColor: [
          "rgba(75, 192, 192, 0.6)", // Greenish
          "rgba(153, 102, 255, 0.6)", // Purple
          "rgba(255, 159, 64, 0.6)", // Orange
          "rgba(255, 99, 132, 0.6)", // Red
          "rgba(54, 162, 235, 0.6)", // Blue
          "rgba(255, 206, 86, 0.6)", // Yellow
        ],
        borderWidth: 1,
        borderColor: "rgba(255, 255, 255, 0.8)",
      },
    ],
  });
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        //"http://192.168.60.100:4100/pqs/jli/feed/viewJliDatav2",
        "https://portaldata.jaktraffic.my.id/pqs/jli/feed/viewJliDatav2",
        {
          params: { page: 1, pto: "TJ", pageSize: 200 },
        }
      );

      const data = response.data?.data || [];
      processChartData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const processChartData = (data: any[]) => {
    const paymentCount: Record<string, number> = {};

    // Count occurrences for payment media
    data.forEach((item) => {
      if (item.payment_media) {
        paymentCount[item.payment_media] =
          (paymentCount[item.payment_media] || 0) + 1;
      }
    });

    const labels = Object.keys(paymentCount);
    const values = Object.values(paymentCount);

    setChartData({
      labels,
      datasets: [
        {
          label: "Payment Media",
          data: values,
          backgroundColor: [
            "rgba(75, 192, 192, 0.6)", // Greenish
            "rgba(153, 102, 255, 0.6)", // Purple
            "rgba(255, 159, 64, 0.6)", // Orange
            "rgba(255, 99, 132, 0.6)", // Red
            "rgba(54, 162, 235, 0.6)", // Blue
            "rgba(255, 206, 86, 0.6)", // Yellow
          ],
          borderWidth: 1,
          borderColor: "rgba(255, 255, 255, 0.8)",
        },
      ],
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="chart-container">
      <div className="chart-title">Distribution of Payment Media</div>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <Doughnut
          data={chartData}
          options={{
            responsive: true,
            plugins: {
              legend: {
                position: "top",
              },
              title: {
                display: true,
                text: "Distribution of Payment Media",
              },
              tooltip: {
                callbacks: {
                  label: function (tooltipItem) {
                    return `${tooltipItem.label}: ${tooltipItem.raw}`;
                  },
                },
              },
            },
            // Adjust the cutout percentage to make the donut smaller
            cutout: "50%", // Smaller value makes the donut smaller
          }}
          width={500} // Width of the chart
          height={500} // Height of the chart
        />
      )}
    </div>
  );
};

export default ChartPaymentTJ;
